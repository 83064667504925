<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :search="search"
            sort-by="order_id"
            :sort-desc="true"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{$route.name}}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="createOrder" small v-if="$store.state.currentPagePriv.create">
                    <v-icon small>mdi-plus</v-icon>
                    Create
                </v-btn>

            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="viewItem(item.order_id)" class="mr-2" v-if="item.status==1">
                    mdi-eye
                </v-icon>
                <v-icon small class="mr-2" @click="editItem(item.order_id)" v-if="!item.status && $store.state.currentPagePriv.update">
                    mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(item.order_id)" v-if="!item.status && $store.state.currentPagePriv.delete">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                    Reset
                </v-btn>
            </template>
            <template v-slot:[`item.production_date`]="{ item }">
              {{item.production_date | moment}}
            </template>
            <template v-slot:[`item.delivery_date`]="{ item }">
              {{item.delivery_date | moment}}
            </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
            <v-card-title class="body-1 text-center">Anda akan menghapus order ini?</v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        </v-dialog>

    </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data: () => ({
      headers: [
        { text: 'Order Code', value: 'order_id' },
        { text: 'Hari Kirim', value: 'delivery_date' },
        { text: 'Group', value: 'order_category' },
        { text: 'Remarks', value: 'remarks' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      search: '',
      dialogDelete: false,
      deletedItem: null
    }),

    computed: {
      profile () {
        return this.$store.state.profile
      },
    },

    watch: {
    },

    created () {
      this.initialize()
    },
    filters: {
        moment: function (date) {
            return moment(date).format('ddd, D MMM YYYY');
        }
    },

    methods: {
      async initialize () {
        let response = null
        if (this.profile.role_code=='SUP')
          response = await this.$store.dispatch('getObjs', {apidomain:'others/planningorderssupadm', parameter:''})
        else
          response = await this.$store.dispatch('getObjs', {apidomain:'others/planningorders', parameter:''})
        this.items = response.data
      },

      async createOrder () {
        this.$router.push('/uploadorderplan/001/new')
      },

      viewItem(order_id) {
        this.$router.push('/uploadorderplan/001/'+order_id)
      },

      editItem(order_id) {
        this.$router.push('/uploadorderplan/001/'+order_id)
      },

      deleteItem(order_id) {
        this.deletedItem = order_id
        this.dialogDelete = true
      },

      async deleteItemConfirm() {
        await this.$store.dispatch('order/deleteObj', this.deletedItem)
        this.dialogDelete = false
        this.initialize()
      }

    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>